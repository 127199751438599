<template>
    <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1200px">      
        <v-card >
            <v-card-title><v-icon large v-if="filedata.relation_status === 0" color="red">device_hub</v-icon><v-icon large v-else-if="filedata.relation_status === 1"  color="amber accent-4">device_hub</v-icon>{{$t('relationMyfile.headerdialog') }} {{filedata.file_name}} <v-spacer></v-spacer><v-icon :color="color.theme" dense dark size="35">device_hub</v-icon></v-card-title>
             <v-divider></v-divider>
        <v-card-text style="height:800px">
          <v-layout row wrap class="pa-2">
            <v-flex xs12 lg12>
            <br/>
            <v-list nav >
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">folder</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                    <template v-slot:item="props">
                      <v-breadcrumbs-item
                        style="cursor: pointer;"
                         @click="gotofolder(props.item)"
                      >
                        <span :style="headerPage">{{
                          props.item.file_name
                        }}</span>
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
                  <v-chip
                  v-if="filedata.relation_status === 0"
                  class="ma-2"
                  color="red"
                  text-color="white"
                  x-small
                  >
                 <v-icon x-small>device_hub</v-icon> {{$t('relationMyfile.master') }}
                  </v-chip>
                  <v-chip
                  v-else-if="filedata.relation_status === 1"
                   class="ma-2"
                  color="amber accent-4"
                  text-color="white"
                  x-small
                  >
                 <v-icon x-small>device_hub</v-icon> {{$t('relationMyfile.child') }}
                   </v-chip>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-group>
                <!-- <v-list-item-action> -->
                <v-btn
                  icon
                  fab
                  small
                  text
                   @click="backtopreventfolder()"
                  :color="color.theme"
                >
                  <v-icon>keyboard_backspace</v-icon>
                </v-btn>
                <!-- </v-list-item-action> -->
              </v-list-item-group>
            </v-list-item>
          </v-list>

            <!-- <v-layout>
                <v-flex class="text-right pb-1" offset-lg8>
                    <v-btn 
                    :color="color.theme"
                    :dark="color.darkTheme" 
                    v-if="filedata.relation_name !== '' && filedata.relation_id !== '' && filedata.status_check !== 'E' && filedata.status_check !== 'V' && filedata.relation_status !== 2 && checkshared === false" 
                    @click="openrelation('1')">{{$t('relationMyfile.addrelation') }}</v-btn>
                    <v-btn  
                    :color="color.theme"
                    :dark="color.darkTheme" 
                    v-else-if="filedata.relation_name === '' && filedata.relation_id === '' && filedata.status_check !== 'E' && filedata.status_check !== 'V' && filedata.relation_status !== 2 && checkshared === false" @click="openrelation('2')">{{$t('relationMyfile.createrelation') }}</v-btn>                    
                </v-flex> -->
              <!-- <v-flex class="text-left pl-1"> <v-btn @click="deleterelation()">Delete Relation</v-btn></v-flex> -->
            <!-- </v-layout> -->
            <div v-if="loaddataprogress === true"><v-flex lg12 class="ma-2 ml-4 mr-4" >
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex></div>
            <div v-else><div v-if="resolutionScreen >= 500">
            <v-data-table
            :headers="headers"
            :items="datarelation"
            :single-select="false"           
            class="elevation-0"                      
            item-selected
            :no-data-text="$t('tablefile.empty')"
            :page.sync="page"
            :items-per-page="size"
            :hide-default-footer="true"
             @page-count="pageCount = $event">
             <template  v-slot:[`header.file_type`]="{ header }">
              <span
                :style="headerTable"
              >{{$t( header.text )}}</span>
             </template>
             <template  v-slot:[`header.file_name`]="{ header }">
              <span
                :style="headerTable"
              >{{$t( header.text )}}</span>
             </template>
             <template v-slot:[`header.file_lastdtm`]="{ header }">
              <span
                :style="headerTable"
              >{{$t( header.text )}}</span>
             </template>
             <template v-slot:[`header.file_size`]="{ header }">
              <span
                :style="headerTable"
              >{{$t( header.text )}}</span>
             </template>
             <template v-slot:[`header.option`]="{ header }">
              <span 
                :style="headerTable"
              >{{$t( header.text )}}</span>
             </template>
             <template v-slot:item="props">
                <tr
                @dblclick="gotodirectory(props.item.file_id,props.item.file_type,props.item)"
                >
                  <td width="7%" class="text-center">             
                        <v-icon
                           large
                            :color="props.item.file_icon[1]"
                        >{{ props.item.file_icon[0] }}</v-icon><br/>
                        <v-chip  v-if="props.item.relation_status === 0"
                             
                              color="red"
                              text-color="white"
                              x-small><v-icon x-small>device_hub</v-icon> {{$t('relationMyfile.master') }}</v-chip>
                        <v-chip v-else-if="props.item.relation_status !== 2"
                              
                              color="amber accent-4"
                             text-color="white"
                             x-small><v-icon x-small>device_hub</v-icon> {{$t('relationMyfile.child') }}</v-chip>
                             
                 </td>
                    <td width="43%" >{{props.item.file_name}}</td>
                    <td width="25%" >{{formatdatetime(props.item.last_dtm)}}</td>
                    <td width="15%" >{{calculatesize(props.item.file_size)}}</td>
                    <td width="10%" class="text-right" >
                      <v-btn class="elevation-0 " fab small @click.stop="downloadfile(props.item)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                      <v-btn v-if="checkshared === false" class="elevation-0 " fab small @click="editrelation(props.item)">
                            <v-icon>mdi-trash-can</v-icon></v-btn>
                      
                            </td>
                 </tr></template>
             </v-data-table>
             </div>
             <div v-else>
                 <v-list two-line class="mb-6 pb-6 elevation-2" v-if="datarelation.length !== 0">
                      <v-list-item
                        v-for="item in paginatedData"
                        :key="item.title"
                        
                        @dblclick="gotodirectory(item.file_id,item.file_type,item)"
                        style="cursor: pointer;"
                      >
                        <v-list-item-action>
                          <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                          <v-chip  v-if="item.relation_status === 0"
                             
                              color="red"
                              text-color="white"
                              x-small><v-icon x-small>device_hub</v-icon> Master</v-chip>
                        <v-chip v-else-if="item.relation_status === 1"
                              
                              color="amber accent-4"
                             text-color="white"
                             x-small><v-icon x-small>device_hub</v-icon> Child</v-chip>
                        </v-list-item-action>

                        <v-list-item-content
                        >
                          <v-list-item-title v-text="item.file_name"></v-list-item-title>
                          <v-list-item-subtitle v-text="calculatesize(item.file_size)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action >
                           <v-btn class="elevation-0 " fab small @click.stop="downloadfile(item)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn v-if="checkshared === false" class="elevation-0 " fab small @click.stop="editrelation(item)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    
                      <v-divider inset></v-divider>
                    </v-list>
                    <v-list v-else class="elevation-1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
             </div>
             </div>
             <br />
                  <v-layout row wrap justify-center>
                     <v-flex xs11 lg11 v-if="datarelation.length > 0">
                      <v-pagination
                      :total-visible="10"
                        v-model="page"
                        :length="pageCount || 0"
                        :color="color.theme"
                        @input="changepage()"
                      ></v-pagination>
                    </v-flex> 
                  </v-layout>
                  </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
           <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn color="red" :dark="color.darkTheme"  @click="$emit('closedrelationfile'),datarelation=[],navigate=[],test=1">{{$t('admin.close')}}</v-btn>
           <!-- <v-btn :color="color.theme" :dark="color.darkTheme" @click="setpermissionfile()">{{$t('admin.setting')}}</v-btn>         -->
        <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowloadfile" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab style="height:40px; width:400px;">
            {{$t('myinboxPage.dowload.dowloading')}}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="colsedowload()" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item v-for="(item,i) in newlist" :key="i" dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <!-- <v-list-item-title>{{namedowload}}</v-list-item-title> -->
            </v-list-item-content>
            <v-progress-circular
              :rotate="-90"
              :size="35"
              :width="3"
              :value="item.value"
              :timeout="timeout"
              color="teal"
              style="margin-left:25px"
            >
              <v-list-item-title v-text="item.value"></v-list-item-title>
            </v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
        </v-card-actions> 
        
         <addrelation
         :show="openaddrelation"
         :filedata="filedata"
         @closedialog="openaddrelation = false,loadrelation()"
         :relation_name="relation_name"
         :statusopen="statusopen"
         @checkparameter="openparameter"

         ></addrelation>  
          <previewfile
              :show="openpreviewfile"
              :filedata="currentfile"
              :parentfolder="parentfolder"
              @closepreviewfile="openpreviewfile = false"
              @openpreviewfile="openpreviewfile = true"
            ></previewfile>
            <dialogsnackbermultidowload
              :show="opensnackbarmultidownload"
              @closedialog="opensnackbarmultidownload = false"
              :percent='percent'
              :date='datetime'
            ></dialogsnackbermultidowload>
            <dialogsnackbardowload
              :show="opensnackbar"
              @closedialog="opensnackbar=false"
            ></dialogsnackbardowload>
        </v-card>    
    </v-dialog>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { required, maxLength, email } from 'vuelidate/lib/validators'
const addrelation = () => import("@/components/optional/dialog-addrelation");
const previewfile = () => import("@/components/optional/dialog-preview");
const dialogsnackbermultidowload = () => import("@/components/optional/dialog-snackbarmultidowmload");
const dialogsnackbardowload =() => import("../optional/dialog-snackbardownload");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
    components:{
        addrelation,
        previewfile,
        dialogsnackbermultidowload,
        dialogsnackbardowload
    },
     mixins: [validationMixin],
   validations: {
      filename: { required },
    },
    props: ['show', "parentfolder", "filedata"],
    data: function() {
      return {
        checkshared:false,
        currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
        },
        opensnackbarmultidownload:false,
        opensnackbar:false,
        percent:0,
        percentCompleted: 0,
        datetime: "",
        timeout: 60000,
        timeoutdowload: 60000000,
        snackbardowloadfile: false,
        listdatadowload: [],
        newlist: [],
        openpreviewfile: false,
        loaddataprogress:false,
        folder_ID:"",
        folder_STATUS:false,
        folder_TYPE:"",
        navigate:[],
        datarelation:[],
        relation_name:"",
        openaddrelation:false,
        statusopen:"",
        test:1,
        size: 20,
        page: 1,
        headers:[
            {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false
        },
        {
          text: "tablefile.filename",
          value: "file_name",
          width: "43%",
          align: "left",
          sortable: false
        },
        // {
        //   text: "tablefile.owner",
        //   value: "file_owner",
        //   width: "20%",
        //   sortable: false
        // },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "25%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "15%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.option",
          value: "option",
          width: "10%",
          align: "left",
          sortable: false
        }
        ]
      }
    },
    computed: {
      ...mapState(['username', 'authorize','account_active','color','role_level']),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
        shower :{
            get() {
                if(this.show === true){
                 //console.log(this.detaildata);
                  if(this.test === 1){
                   console.log(this.$route.name);
                   if(this.$route.name === 'sharedwithme' || this.$route.name === 'directorysharefile'){
                      this.checkshared = true
                      console.log(this.checkshared);
                   }
                  //if(this.filedata.file_type === "folder"){
                   this.navigate.push({file_name:this.filedata.file_name,file_id:this.filedata.file_id});
                 // }
                 console.log(this.navigate);
                  this.loadrelation();
                  console.log(this.filedata);
                  console.log(this.parentfolder);
                 }
                  
                  this.test = 0;
                }
                return this.show
            },
            set (value) {
                if (!value) {
                 this.selected=[];
                }
            }
        },
        colorProgress(){
      return 'color:'+this.color.theme+';'
    }
    ,headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      // console.log(this.color.theme);
      
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorSort(){
      return 'color:'+this.color.alertText+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    },
     resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
     pageCount: {
      get() {
        let l = this.datarelation.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.datarelation.slice(start, start + this.size);
    },
  },
  methods:{
     colsedowload() {
      this.snackbardowloadfile = false;
      this.newlist = [];
      this.listdatadowload = [];
    },

   async multipledownload() {
      console.log("CRC", this.filemultipledownload);
      this.percentCompleted = 0;
      this.percent = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile")
        });
      } else {
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          // push type
          if (this.filemultipledownload[i]["file_type"] === "folder") {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: this.filemultipledownload[i]["file_type"]
            });
          } else {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: "file"
            });
          }
        }
        let auth = await gbfGenerate.generateToken();
        let payload = {
          list: filedata,
          account_id: this.dataAccountId
        };
        console.log("payloads", payload);
        // this.snackbardowloadfile = true;
        this.opensnackbarmultidownload = true;
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
              "/api/download_multiples_all",
            payload,
            {
              onDownloadProgress: progressEvent => {
                // console.log(progressEvent);
                let progresspercent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                // console.log(progresspercent);
                if (progresspercent >= 95) {
                  this.percentCompleted = 95;
                } else {
                  this.percentCompleted = progresspercent;
                }
                this.percent = this.percentCompleted;
              },
              withCredentials: false,
              responseType: "arraybuffer",
              headers: { Authorization: auth.code },
            }
          )
          .then(response => {
            console.log(response);
            // if (response.statusText === "OK") {
            //   this.percentCompleted = 100;
            // }
            // const blob = new Blob([response.data]);
            // const content = response.headers["content-type"];
            // saveAs(blob, currentfile.file_name + ".zip");
            if (response.status === 200) {
              if (
                parseInt(response.headers["content-lenght"]) < 700 &&
                (response.headers["content-type"]) ===
                  "application/json"
              ) {
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percentCompleted = 100;
                const blob = new Blob([response.data]);
                const content = response.headers["content-type"];
                saveAs(blob, this.datetime + ".zip");
              }
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
            this.clearmultipledownload();
          })
          .catch(error => {
            this.opensnackbarmultidownload = true;
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror")
            });
            console.log(error);
          });
      }
    },
    async downloadfile(currentfile) {
       console.log(currentfile);
      let datadowload = {};

      console.log(this.listdatadowload);
      let checkdata = this.listdatadowload.findIndex(
        v => v.file_id === currentfile.file_id
      );
      if (checkdata != -1) {
        Toast.fire({
          icon: "error",
          title: "พบการดาวน์โหลดซ้ำ กรุณาดาวน์โหลดใหม่อีกครั้ง"
        });
        this.snackbardowloadfile = false;
        // this.opensnackbarmultidownload = true;
        this.listdatadowload = [];
        this.newlist = [];
      } else {
        this.listdatadowload.push(currentfile);

        console.log(this.listdatadowload);

        for (let i = 0; i < this.listdatadowload.length; i++) {
          datadowload["fileid"] = this.listdatadowload[i].file_id;
          datadowload["name"] = this.listdatadowload[i].file_name;
          datadowload["value"] = 0;
          console.log(datadowload);
        }

        this.newlist.push(datadowload);
        console.log(this.newlist);

        // for(let i = 0; i < this.newlist.length;i++){
        //  let checkdata = this.newlist.findIndex(v => v.fileid === this.newlist.fileid);
        //   if(checkdata == -1) {

        //     console.log("ไม่เจอตัวซ้ำ",checkdata);
        //   }else{
        //     console.log('เจอออซ้ำ');

        //   }

        // }

        this.percentCompleted = 0;
        if (currentfile.file_type === "folder") {
          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
            "/api/v2/download_folder?account_id=" +
            this.dataAccountId +
            "&folder_id=" +
            currentfile.file_id +
            "&account_id=" + this.dataAccountId
          // this.namedowload = currentfile.file_name;

          for (let i = 0; i < this.listdatadowload.length; i++) {
            this.dataIndex = this.newlist.findIndex(
              obj => obj.fileid === this.listdatadowload[i].file_id
            );
          }
          console.log("folder", this.dataIndex);

          this.snackbardowloadfile = true;
          await this.axios
            .get(url, {
              onDownloadProgress: progressEvent => {
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
               console.log(this.percentCompleted);
              // console.log(this.snackbardowloadfile);
                if (this.newlist[this.dataIndex]["value"] === 95) {
                  this.newlist[this.dataIndex]["value"] = 95;
                } else {
                  this.newlist[this.dataIndex]["value"] = progresspercent;
                }
              },
              withCredentials: false,
              responseType: "arraybuffer"
            })
            .then(response => {
              console.log(response);
              if (response.status === 200) {
                if (
                  parseInt(response.headers["content-lenght"]) < 700 &&
                  (response.headers["content-type"]) ===
                    "application/json"
                ) {
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(
                    response.data
                  );
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.newlist[this.dataIndex]["value"] = 100;
                  const blob = new Blob([response.data]);
                  const content = response.headers["content-type"];
                  saveAs(blob, this.datetime + ".zip");
                }
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              }
              // if (response.statusText === "OK") {
              //   this.newlist[this.dataIndex]["value"] = 100;
              // }
              // const blob = new Blob([response.data]);
              // const content = response.headers["content-type"];
              // saveAs(blob, currentfile.file_name + ".zip");
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
              });
              this.snackbardowloadfile = false;
              this.listdatadowload = [];
              this.newlist = [];
              console.log(error);
            });
        } else {
          let payload = {
            account_id: this.dataAccountId,
            user_id: this.dataUsername,
            file_id: currentfile.file_id
          };
          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/v2/download_file?user_id=" +
            this.dataUsername +
            "&file_id=" +
            currentfile.file_id +
            "&account_id=" + this.dataAccountId

          for (let i = 0; i < this.listdatadowload.length; i++) {
            this.dataIndex = this.newlist.findIndex(
              obj => obj.name === this.listdatadowload[i].file_name
            );
          }
          console.log("file", this.dataIndex);
          this.snackbardowloadfile = true;
          await this.axios
            .get(url, {
              onDownloadProgress: progressEvent => {
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(this.percentCompleted);
                //console.log(this.snackbardowloadfile);
                // this.namedowload = currentfile.file_name;
                if (this.newlist[this.dataIndex]["value"] === 95) {
                  this.newlist[this.dataIndex]["value"] = 95;
                } else {
                  this.newlist[this.dataIndex]["value"] = progresspercent;
                }
              },
              withCredentials: false,
              responseType: "arraybuffer"
            })
            .then(response => {
              if (response.status === 200) {
                try {
                  let type = currentfile.file_name.split(".");
                  type = type[type.length - 1].toLowerCase();
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  if (res_error.status === "ER" && type !== "json") {


                  } else {
                    this.newlist[this.dataIndex]["value"] = 100;
                    const blob = new Blob([response.data]);
                    const content = response.headers["content-type"];
                    saveAs(blob, currentfile.file_name);
                  }
                } catch (error) {
                  console.log("error", error);
                  this.newlist[this.dataIndex]["value"] = 100;
                  const blob = new Blob([response.data]);
                  const content = response.headers["content-type"];
                  saveAs(blob, currentfile.file_name);
                }
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              }
              this.snackbardowloadfile = false;
              // if (response.statusText === "OK") {
              //   this.newlist[this.dataIndex]["value"] = 100;
              // }
              // // console.log("fff",response);
              // // this.namedowload = currentfile.file_name;
              // const blob = new Blob([response.data]);
              // const content = response.headers["content-type"];
              // saveAs(blob, currentfile.file_name);
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
              });
              this.snackbardowloadfile = false;
              this.listdatadowload = [];
              this.newlist = [];
              console.log(error);
            });
        }
      }
    },
   async editrelation(parameter){
      console.log(parameter.rel_id,parameter);
       var payload = {
          rel_id: parameter.rel_id,
          account_id: this.dataAccountId
         };
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
              "/api/edit_file_folder_relation",
            payload,{headers: { Authorization: auth.code },}
          )
          .then((response) => {
             console.log(response);
              if(response.data.status === "OK"){
                 Toast.fire({
              icon: "success",
              title: "delete relation success",
            });
                this.loadrelation();
              }else{
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
          }).catch((error)=>{
             Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          });
    },
    changepage() {
      this.loaddataprogress = true;
      setTimeout(() => {
        this.loaddataprogress = false;
      }, 1000);
    },
    backtopreventfolder(){    
      if(this.navigate.length === 2){
       // this.navigate.pop();
       this.navigate.pop();
        this.loadrelation();
        
      }else if(this.navigate.length === 1){
        this.loadrelation();
      }else{
      this.navigate.pop();
      this.loaddatafolder(this.navigate[this.navigate.length-1].file_id);
      console.log(this.navigate);
      
      
      }
    },
    gotofolder(parameter){
      console.log(parameter);
      //console.log(this.navigate[this.navigate.length-1]);
      if(parameter.file_id === this.filedata.file_id){
        this.navigate.splice(1);
        this.loadrelation();
      }else{    
        for(let i=0;i<this.navigate.length;i++){
          if(parameter.file_id === this.navigate[i].file_id){
            console.log(i);
          
            this.navigate.splice(i+1);
            break;
          }
        }
      console.log(this.navigate);
      this.loaddatafolder(parameter.file_id);
      }
      
    },
    openparameter(values){
      console.log("value",values);
      this.filedata.relation_id = values.relation_id
      this.filedata.relation_name = values.relation_name
      //this.loaddata()
      this.openaddrelation=false;
    },
    gotodirectory(folderid, type, file) {
      if (type === "folder") {
        if(folderid !== this.navigate[this.navigate.length-1].file_id){
         this.navigate.push({file_id:folderid,file_name:file.file_name});
         console.log(this.navigate);
        }
        this.folder_ID = folderid;
        this.folder_TYPE = type;
        this.folder_STATUS = true;
        this.loaddatafolder(folderid);
      } else {
        console.log("preview");
        this.currentfile = file;
        this.openpreviewfile = true;
      }
    },
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
},
    formatdatetime(_datetime) {
    if (_datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
  },
  async  loadrelation(){
   this.loaddataprogress = true;
   console.log("loadrelation");
    
         var payload = {
          data_id: this.filedata.file_id,
          data_type: this.filedata.file_type,
          account_id: this.dataAccountId
         };
      console.log(payload);
      let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
              "/api/get_file_folder_relation",
            payload,{headers: { Authorization: auth.code },}

          )
          .then((response) => {
             console.log(response);
             this.datarelation=[];
              if(response.data.status === "OK"){
                 this.datarelation=[];
                console.log(response.data);
                this.relation_name = response.data.relation_name;
              
              for(let i =0;i<response.data.data.length;i++){
                if(response.data.data[i]["types"] === "folder"){
                  let databuffer = {};
                  let typefile = response.data.data[i]["name"].split(".");
                  databuffer["file_id"] = response.data.data[i]["id"];
                  databuffer["rel_id"] = response.data.data[i]["rel_id"];
                  databuffer["file_name"] = response.data.data[i]["name"]
                  databuffer["parent_file_relation"] = response.data.data[i]["folder_id"]
                  databuffer["last_dtm"] = response.data.data[i]["last_dtm"]
                  databuffer["file_size"] = response.data.data[i]["size"]
                  databuffer["relation_status"] = response.data.data[i]["relation_status"]
                  databuffer["status_share"] = response.data.data[i]["status_share"]
                   databuffer["file_permission"] =response.data.data[i]["permission_account"];
                  databuffer["status_share_link"] = response.data.data[i]["status_share_link"];
                  if(response.data.data[i]["types"] === "folder"){
                    databuffer["file_type"] = response.data.data[i]["types"]
                    databuffer["file_icon"] = ["folder", "#FDD361"];
                  }else{
                    databuffer["file_type"] = typefile[typefile.length - 1];
                    databuffer["file_icon"] = this.seticon(typefile[typefile.length - 1]);
                  }                               
                  this.datarelation.push(databuffer);
                }
                  //this.folder_STATUS = false;
              }
              for(let i =0;i<response.data.data.length;i++){
                if(response.data.data[i]["types"]  !== "folder"){
                  let databuffer = {};
                  let typefile = response.data.data[i]["name"].split(".");
                  databuffer["file_id"] = response.data.data[i]["id"];
                  databuffer["rel_id"] = response.data.data[i]["rel_id"];
                  databuffer["file_name"] = response.data.data[i]["name"]
                  databuffer["parent_file_relation"] = response.data.data[i]["folder_id"]
                  databuffer["relation_status"] = response.data.data[i]["relation_status"]
                  databuffer["last_dtm"] = response.data.data[i]["last_dtm"]
                  databuffer["file_size"] = response.data.data[i]["size"]
                  databuffer["status_share"] = response.data.data[i]["status_share"]
                  databuffer["status_share_link"] = response.data.data[i]["status_share_link"];
                  databuffer["file_permission"] =response.data.data[i]["permission_account"];
                  if(response.data.data[i]["types"] === "folder"){
                    databuffer["file_type"] = response.data.data[i]["types"]
                    databuffer["file_icon"] = ["folder", "#FDD361"];
                  }else{
                    databuffer["file_type"] = typefile[typefile.length - 1];
                    databuffer["file_icon"] = this.seticon(typefile[typefile.length - 1]);
                  }                               
                  this.datarelation.push(databuffer);
                  //this.folder_STATUS = false;
                }
              }
              this.loaddataprogress = false;
              }else{
                 Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
              }
              console.log("wwewewewewe",this.datarelation);
          }).catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        })
 },
    seticon(parameter) {
      // console.log(parameter);
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box-outline", "blue-grey"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    openrelation(status){
    this.openaddrelation=true;
    this.statusopen = status
  },
    async loaddatafolder(folderid_){
    console.log("loaddatafolder");
    this.loaddataprogress = true;
      var payload={
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folderid_,
        status_folder: ""
      };
      let auth = await gbfGenerate.generateToken();
     this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
            "/api/search_folder_in_relation",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(response => {
          //console.log("Apifolder", response);
          this.datarelation=[];
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {
            for(let i =0;i<response.data.folder.length;i++){
                  //let typefile = response.data.data[i]["name"].split(".");
                 let datafolder = {}
                        datafolder["file_id"] = response.data.folder[i]["folder_id"];
                        datafolder["file_name"] = response.data.folder[i]["folder_name"];
                        datafolder["file_size"] = "-";
                        datafolder["file_lastdtm"] = response.data.folder[i]["cre_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_linkshare"] = response.data.folder[i]["link"];
              datafolder["file_status_share"] = response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] =response.data.folder[i]["status_share_link"];
              datafolder["file_status"] = response.data.folder[i]["folder_status"];
              datafolder["file_owner_eng"] = response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["file_type"] = "folder";
              datafolder["file_department"] = "";
              datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["file_parent_folder_id"] =response.data.folder[i]["parent_folder_id"];
              datafolder["file_permission"] =
                response.data.folder[i]["permission_setting"] === "True"
                  ? response.data.folder[i]["permission_account"]
                  : response.data.your_folder[0]["permission_account"];
              datafolder["file_permission_department_setting"] =
                response.data.folder[i]["permission_setting"] === "True"
                  ? response.data.folder[i]["permission_department_setting"]
                  : response.data.your_folder[0][
                      "permission_department_setting"
                    ];
              datafolder["file_permission_setting"] =
                response.data.folder[i]["permission_setting"] === "True"
                  ? response.data.folder[i]["permission_setting"]
                  : response.data.your_folder[0]["permission_setting"];
                  datafolder["relation_id"]=response.data.folder[i]["relation_id"];
                  datafolder["relation_name"]=response.data.folder[i]["relation_name"];
                  datafolder["relation_status"]=response.data.folder[i]["relation_status"];
                  
                  
                  this.datarelation.push(datafolder);
            }
            this.loaddatafile(folderid_,response.data.your_folder[0]);
          }else{
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
          }).catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });   
  },
   async loaddatafile(folderid,current_folder){
     var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folderid,
          status: "",
        };
        let auth = await gbfGenerate.generateToken();
       await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/search_file_in_relation",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
        console.log(response);
        this.loaddataprogress = false;
        let rawdata = response.data.data;
          if (response.data.status === "OK") {
            for(let i =0 ; i<response.data.data.length;i++){
             let datafolder = {};
              let typefile = rawdata[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = rawdata[i]["id"];
              datafolder["file_name"] = rawdata[i]["filename"];
              datafolder["file_size"] = rawdata[i]["size_file"];
              datafolder["file_lastdtm"] =
                response.data.data[i]["last_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_status"] = rawdata[i]["status_file"];
              datafolder["file_owner_eng"] = rawdata[i]["name_eng"];
              datafolder["file_owner_th"] = rawdata[i]["name_th"];
              datafolder["file_linkshare"] = rawdata[i]["link"];
              datafolder["file_status_share"] = rawdata[i]["status_share"];
              datafolder["file_status_sharelink"] =
                rawdata[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
             datafolder["file_permission"] =
                rawdata[i]["permission_setting"] === "True"
                  ? rawdata[i]["permission_account"]
                  : current_folder["permission_account"];
              datafolder["file_icon"] = this.seticon(
                typefile[typefile.length - 1]
              );
              datafolder["relation_id"]=rawdata[i]["relation_id"];
              datafolder["relation_name"]=rawdata[i]["relation_name"];
              datafolder["relation_status"]=rawdata[i]["relation_status"];
              this.datarelation.push(datafolder);
            }
          }else{
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
          console.log(this.datarelation);
          
          }).catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
  }
  },
  
  
}
</script>
<style>

</style>